<template>

  <div class="pbf">
     
     <TabAccounts v-if="data"
       :data="data"
       />

  </div>

</template>

<script setup>

import TabAccounts from '@/pages/brands-manager/components/TabAccounts'

const props = defineProps({
  data: {
    type: Object    
  }
});

useHead(useNuxtApp().$head.getBrandManager());

/*  
export default { 
  props: {
    data: {
      type: Object
    }
  },
  components: {     
    TabAccounts
  },
  head() { 
    return this.$head.getBrandManager()
  }
}
*/
</script>

<style scoped>



/**/
 
</style>

<style>
 
/* accounts */

.accounts_content{

}
.accounts_content .input_item{
  width: 340px;
  margin-right: 10px;
}
.accounts_content .create_container{
    display: flex;
    align-items: center;
}

.accounts_content .button_item{
  width: 160px;
}

.accounts_content table{
  width: 100%!important;
}

.accounts_content .table td .user_ava img{
  width: 28px;
  height: 28px;
  margin-right: 4px;
  float: left;
  border-radius: 100%;
}
.accounts_content .table td.nm a{
  vertical-align: middle;
}
.accounts_content .table td.rl{
  width: 150px;
  vertical-align: middle;
}
.accounts_content .table td.ac{
  width: 50px;
}

@media (max-width: 768px) {
  .accounts_content .input_item{
    width: 160px;
    margin-right: 5px;
  }
  .accounts_content .table td.user_ava{
    display: none;
  }
  .accounts_content .table td.rl{
    width: auto;
  }
}

</style>